import clsx from "clsx";
import React from "react";
import styles from "./CustomEmptyData.module.scss";
import { P_Props } from "./CustomEmptyData.type";

const CustomEmptyDataPresenter: React.FC<P_Props> = (props) => {
  const renderMessage = () => {
    if (Boolean(props.messages.length) && !props.breakLine) {
      return (
        <div className={styles.custom__empty__data__message}>
          {props.messages.join(" ")}
        </div>
      );
    } else if (Boolean(props.messages.length) && props.breakLine) {
      return (
        <div className={styles.custom__empty__data__message}>
          {props.messages.map((data, i) => {
            return (
              <React.Fragment key={i}>
                {data} <br />
              </React.Fragment>
            );
          })}
        </div>
      );
    } else return null;
  };

  const emptyDataClass = clsx({
    [styles.custom__empty__data]: true,
    [styles.custom__empty__data + props.type]: Boolean(props.type),
    [props.className || ""]: Boolean(props.className),
  });
  return (
    <div style={props.style} className={emptyDataClass}>
      {props.icon && (
        <div className={styles.custom__empty__data__icon}>{props.icon}</div>
      )}
      {props.title && (
        <div className={styles.custom__empty__data__title}>{props.title}</div>
      )}
      {renderMessage()}
    </div>
  );
};

export default CustomEmptyDataPresenter;
