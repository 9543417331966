import { SUPPLIER_API } from "../../constants/routes/api-supplier";
import ApiV3Suplier from "../../Utils/api";

const SupplierCardService = {
  checkOriginCard,
};

function checkOriginCard(code: string) {
  return ApiV3Suplier.get({
    url: SUPPLIER_API.CHECK_CARD_SUPPLIER.replace("{CODE}", code),
  });
}

export default SupplierCardService;
