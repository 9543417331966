import { notification } from "antd";
import Axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import axiosRetry, { IAxiosRetryConfig } from "axios-retry";
import { API_DOMAIN } from "../constants/api";
import { MESSAGE } from "../constants/message";

type RetryConfig = Omit<IAxiosRetryConfig, "retryDelay"> & {
  retryDelay?: number;
};
class ApiV3 {
  private axiosInstance: AxiosInstance;
  readonly defaultHeaders: any = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  constructor() {
    let headers: any = this.defaultHeaders;
    // headers = { ...headers, ...this.getAuthHeader() };
    const axiosRequestConfig: AxiosRequestConfig = {
      baseURL: API_DOMAIN,
      timeout: 60000,
      headers,
    };
    const axiosInstance: AxiosInstance = Axios.create(axiosRequestConfig);
    axiosRetry(axiosInstance, { retries: 1 });
    // this.createAxiosResponseInterceptor(axiosInstance);
    this.axiosInstance = axiosInstance;
  }
  // createAxiosResponseInterceptor(axiosInstance) {
  //   const interceptor = axiosInstance.interceptors.response.use(
  //     (response) => response,
  //     (error) => {
  //       // return if not have response or not 401 unauthorized
  //       if (
  //         !error.response ||
  //         (error.response && error.response.status !== 401)
  //       ) {
  //         if (!APP_SETTINGS.IS_DEMO && APP_SETTINGS.IS_ENCRYPT) {
  //           console.error({
  //             debug_api: {
  //               status: error.response?.status,
  //               url: error.config?.url,
  //               code: error.code,
  //               data: error.response?.data,
  //             },
  //           });
  //         }
  //         return Promise.reject(error);
  //       }
  //       // eject to prevent looping
  //       axiosInstance.interceptors.response.eject(interceptor);
  //       // get refreshToken from localStorage
  //       const refreshToken: StringNull = TokenStorageService.getRefreshToken();
  //       if (!refreshToken) {
  //         sessionStorage.removeItem(STORAGE.SESSION_CURRENT_DATE);
  //         localStorage.clear();
  //         window.location.reload();
  //         return Promise.reject(null);
  //       }
  //       // call api refresh token
  //       return axiosInstance
  //         .post(ROUTE.API.MANAGER_REFRESH_TOKEN, { refreshToken })
  //         .then((res) => {
  //           const { accessToken, refreshToken } = res.data.data;
  //           TokenStorageService.setToken({ accessToken, refreshToken });
  //           error.response.config.headers["Authorization"] =
  //             "Bearer " + accessToken;
  //           return axiosInstance(error.response.config);
  //         })
  //         .catch((err) => {
  //           sessionStorage.removeItem(STORAGE.SESSION_CURRENT_DATE);
  //           localStorage.clear();
  //           window.location.reload();
  //           return Promise.reject(err);
  //         })
  //         .finally(() => this.createAxiosResponseInterceptor(axiosInstance));
  //     }
  //   );
  // }
  updateHeader() {
    let headersConfig: any = {
      ...this.defaultHeaders,
      // ...this.getAuthHeader(),
    };
    this.axiosInstance.defaults.headers = headersConfig;
  }
  // getAuthHeader() {
  //   const data: any = {};
  //   let token = StorageUtil.getItem(STORAGE.LOCAL_ACCESS_TOKEN);
  //   if (token) {
  //     data.Authorization = "Bearer ".concat(token);
  //   }
  //   const offsetMinutes = new Date().getTimezoneOffset();
  //   data.gmtOffset = offsetMinutes / -60;
  //   if (APP_SETTINGS.IS_ENCRYPT) {
  //     data.encrypt = true;
  //   }
  //   return { ...data };
  // }
  setHeaders() {
    let headersConfig = {
      "Content-Type": "application/json",
    } as any;
    return (this.axiosInstance.defaults.headers = headersConfig);
  }
  handleError(error: any, hiddenServerError?: boolean) {
    if (error) {
      if (error.response) {
        let description: string = error.response.data.message;
        if (!Boolean(hiddenServerError)) {
          notification.error({ message: MESSAGE.ERROR.BASE, description });
        }
        return error.response;
      } else
        notification.error({
          message: MESSAGE.ERROR.BASE,
          description: MESSAGE.ERROR.NETWORK,
        });
    } else {
      notification.error({
        message: MESSAGE.ERROR.BASE,
        description: MESSAGE.ERROR.NETWORK,
      });
    }
  }
  get(
    input: { url: string; query?: any },
    headers: any = {},
    hiddenServerError?: boolean,
    retryConfig?: RetryConfig
  ): Promise<void | AxiosResponse<any>> {
    return this.send(
      {
        method: "get",
        url: input.url,
        params: input.query || {},
        data: {},
        headers,
      },
      hiddenServerError
    );
  }
  post(
    input: { url: string; data: any; query?: any },
    headers: any = {},
    hiddenServerError?: boolean,
    retryConfig?: RetryConfig
  ): Promise<void | AxiosResponse<any>> {
    return this.send(
      {
        method: "post",
        url: input.url,
        params: input.query || {},
        data: input.data,
        headers,
      },
      hiddenServerError
    );
  }
  put(
    input: { url: string; data?: any; query?: any },
    headers: any = {},
    hiddenServerError?: boolean,
    retryConfig?: RetryConfig
  ): Promise<void | AxiosResponse<any>> {
    return this.send(
      {
        method: "put",
        url: input.url,
        params: input.query || {},
        data: input.data,
        headers,
      },
      hiddenServerError
    );
  }
  delete(
    input: { url: string; query?: any },
    headers: any = {},
    hiddenServerError?: boolean,
    retryConfig?: RetryConfig
  ): Promise<void | AxiosResponse<any>> {
    return this.send(
      input.query
        ? { method: "delete", url: input.url, params: input.query, headers }
        : { method: "delete", url: input.url, headers },
      hiddenServerError
    );
  }
  head(
    input: { url: string; query?: any },
    headers: any = {},
    hiddenServerError?: boolean,
    retryConfig?: RetryConfig
  ): AxiosPromise {
    return this.send(
      {
        method: "head",
        url: input.url,
        params: input.query || {},
        headers,
      },
      hiddenServerError
    );
  }
  send(
    options: AxiosRequestConfig,
    hiddenServerError?: boolean,
    retryConfig?: RetryConfig
  ): AxiosPromise {
    // options = this.encryptParams(options);
    const params: any = {
      ...options,
      headers: {
        ...this.axiosInstance.defaults.headers,
        ...options.headers,
      },
    };
    if (retryConfig) {
      const retryObj: any = { ...retryConfig };
      if (retryConfig.retryDelay) {
        retryObj.retryDelay = () => retryConfig.retryDelay;
      }
      params["axios-retry"] = retryObj;
    }
    // add Authorization to header as defaults
    if (!params.headers.Authorization) {
      this.updateHeader();
      // params.headers.Authorization = this.getAuthHeader().Authorization;
    }
    // remove undefined value
    for (const header in params.headers) {
      if (params.headers.hasOwnProperty(header)) {
        if (params.headers[header] === undefined) {
          delete params.headers[header];
        }
      }
    }
    return this.axiosInstance(params);
  }
  // encryptParams(options: AxiosRequestConfig) {
  //   // if (!APP_SETTINGS.IS_ENCRYPT) {
  //   //   return options;
  //   // }
  //   if (options.data && !(options.data instanceof FormData)) {
  //     // options.data = { data: UrlUtil.encryptPayload(options.data) };
  //   }
  //   if (options.params && Object.keys(options.params).length) {
  //     const dataParams: { [key: string]: string | number | object | any } = {};
  //     for (const [key, value] of Object.entries(options.params)) {
  //       const cValue: any = value;
  //       if (
  //         cValue?.length ||
  //         typeof cValue === "string" ||
  //         (typeof cValue === "number" && !isNaN(cValue))
  //       ) {
  //         dataParams[key] = cValue;
  //       }
  //       if (typeof cValue === "boolean") {
  //         dataParams[key] = String(cValue);
  //       }
  //     }
  //     // options.params = { data: UrlUtil.encryptPayload(dataParams) };
  //   }
  //   return options;
  // }
  // decryptRes(axiosInstance: AxiosPromise<any>): AxiosPromise {
  //   // if (!APP_SETTINGS.IS_ENCRYPT) {
  //   //   //next
  //   //   return new Promise((resolve) => {
  //   //     return resolve(axiosInstance);
  //   //   });
  //   // }
  //   return axiosInstance.then((res) => {
  //     // const bytesParams = UrlUtil.decryptPayload((res.data as any)?.data);
  //     // if (bytesParams) {
  //     //   res.data.data = bytesParams;
  //     // }
  //     return new Promise((resolve) => {
  //       if (
  //         !APP_SETTINGS.IS_DEMO &&
  //         APP_SETTINGS.IS_ENCRYPT &&
  //         res &&
  //         (res.status === 200 || res.status === 201)
  //       ) {
  //         console.log({
  //           debug_api: {
  //             status: res.status,
  //             url: res.config?.url,
  //             data: res.data?.data,
  //           },
  //         });
  //       }
  //       return resolve(res);
  //     });
  //   });
  // }
}
export default new ApiV3();
