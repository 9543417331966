import { TFunction } from "i18next";
import { APP_COLOR } from "../constants/color";
import { CaseDetailModel } from "../models/dentalLab";

export const LabUtil = {
  renderColorExpire,

  getNumberOfTeeth,
};

// status khac develivered va cancelled
// qua thoi gian mau do
// sap den ngay due day theo setting mau vang
function renderColorExpire(data: CaseDetailModel, fallback?: string) {
  const defaultColor = fallback ?? "transparent";
  if (!data || !data.priority) return defaultColor;
  // if (data.status === EC_STATUS.DELIVERED || data.status === EC_STATUS.CANCELED) return defaultColor;
  // if (!data.dueDate) return defaultColor;
  // if (moment().isAfter(moment(data.dueDate))) return APP_COLOR.RED;
  // if (moment().isBefore(moment(data.dueDate)) && moment().isAfter(moment(data.dueDate).subtract(10, "days")))
  //   return APP_COLOR.YELLOW;
  if (Number(data.priority) === 2) return APP_COLOR.RED;
  if (Number(data.priority) === 1) return APP_COLOR.YELLOW;
  return defaultColor;
}

function getNumberOfTeeth(teethNumbers: string[], translation: TFunction) {
  const valueOfTeeth = (teethNumbers || []).map((t) => {
    const teeth = t.split("-");

    // bridge teeth
    if (!!teeth[1]) {
      return `R${teeth[0]}-R${teeth[1]}`;
    }

    // action teeth
    if (Number.isNaN(Number(teeth))) {
      return translation(`dentalLab:${teeth[0]}`);
    }

    // single number tooth
    return `R${teeth}`;
  });

  return valueOfTeeth;
}
