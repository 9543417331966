export enum APP_COLOR {
  DEFAULT = "#525560",
  TRANSPARENT = "transparent",
  WHITE = "#FFFFFF",
  BLACK = "#000000",
  BLUE = "#08C4E3",
  PURPLE = "#6658F3",
  DISABLED = "#ebecf0",
  YELLOW = "#fabc1c",
  RED = "#f84948",
  SECONDARY = "#aeb8c7",
  DARK_SECONDARY = "#444c53",
  GREEN = "#40DD7F",
  DECAY = "#FFB0AF", // nền răng sâu
  MULTI_DISEASE_TOOTH = "#FFE7F1",
  OTHER_DISEASE_TOOTH = "#FFD74B",
  DARK_GRAY = "#272B2F", // thường dùng cho custom button outline,
  LIGHT_GRAYISH_BLUE = "#f0eefe",
  LINK = "#1890ff",
}
